import { fromJS } from 'immutable';
import { INC_NEW_REWARDS_COUNT, SET_NEW_REWARDS_COUNT } from './actions';

const initialState = fromJS({
  newRewardsCount: 0
});

export default function rewardsButtonReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_REWARDS_COUNT:
      return state.set('newRewardsCount', action.payload.count);

    case INC_NEW_REWARDS_COUNT:
      return state.set('newRewardsCount', state.get('newRewardsCount') + 1);

    default:
      return state;
  }
}
