import { fromJS } from 'immutable';
import { SET_ONBOARD_ACTIVE } from './actions';

const initialState = fromJS({ active: false });

export default function onboardTourReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ONBOARD_ACTIVE:
      return state.set('active', action.payload.active);

    default:
      return state;
  }
}
