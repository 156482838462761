/**
 * soundMiddleware
 *
 * Redux middleware used to play sounds with certain actions. Uses [howler.js](https://github.com/goldfire/howler.js)
 * to play sounds.
 *
 * Concept originally based on https://github.com/joshwcomeau/redux-sounds. That library was/is not being
 * maintained and did not support howler.js v2 so we did not use it.
 */

import { LOOP_SOUND, STOP_LOOP_SOUND } from 'containers/App/actions';
import howlerIntegration from './howlerIntegration';

export default function soundMiddleware(soundData) {
  if (typeof soundData !== 'object') {
    throw new Error(`
      Please provide an object to soundsMiddleware!
      When initializing, it needs an object holding all desired sound data.
    `);
  }

  // Set up our sounds object, and pre-load all audio files.
  // Our sounds object basically just takes the options provided to the
  // middleware, and constructs a new Howl object for each one with them.
  howlerIntegration.initialize(soundData);

  return store => next => action => {
    // Ignore actions that haven't specified a sound.
    if (!action.meta || !action.meta.sound) {
      return next(action);
    }

    const [soundName, spriteName] = action.meta.sound.split('.');

    switch (action.type) {
      case LOOP_SOUND:
        howlerIntegration.fadeIn(soundName);
        howlerIntegration.play(soundName, spriteName, true);
        break;

      case STOP_LOOP_SOUND:
        howlerIntegration.fadeOutAndStop(soundName);
        break;

      default:
        howlerIntegration.play(soundName, spriteName);
    }

    return next(action);
  };
}
