import TagManager from 'react-gtm-module';
import { isAuthenticated } from 'utils/auth';
import { USER_QUERY } from 'utils/graphql/userQueries';
import { isMobileApp } from 'utils/mode';

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
const gtmAuth = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH;
const gtmPreview = process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW;
const gtmDataLayerName = 'gtmDataLayer';

const tagManagerArgs = {
  gtmId: gtmId,
  auth: gtmAuth,
  preview: gtmPreview,
  dataLayer: {
    // For security, block custom scripts and triggers that could scrape private info or perform malicious actions
    'gtm.whitelist': ['google', 'nonGooglePixels', 'customPixels'],
    'gtm.blacklist': ['cl', 'fsl', 'lcl', 'k', 'd', 'j', 'jsm']
  },
  dataLayerName: gtmDataLayerName
};

const gtmEnabled =
  gtmId && !isMobileApp() && process.env.NODE_ENV === 'production';

let apolloClient = null;

export const initializeGoogleTagManager = client => {
  apolloClient = client;
  if (gtmEnabled) {
    TagManager.initialize(tagManagerArgs);
  }
};

function setData(dataLayerObj) {
  TagManager.dataLayer({
    dataLayer: dataLayerObj,
    dataLayerName: gtmDataLayerName
  });
}

function determineUserId() {
  let promise = undefined;
  if (apolloClient && isAuthenticated()) {
    try {
      promise = apolloClient
        .query({ query: USER_QUERY })
        .then(({ data }) => {
          const userId = data && data.user && data.user.userId;
          return userId || undefined;
        })
        .catch(() => undefined);
    } catch (e) {}
  }

  return promise || Promise.resolve(undefined);
}

export const setGtmUserId = userId => {
  if (gtmEnabled) {
    setData({
      userId: userId || undefined
    });
  }
};

export const clearGtmUserId = () => setGtmUserId(undefined);

let currentPage = '';

export const trackPage = page => {
  if (gtmEnabled) {
    determineUserId().then(userId => {
      setData({
        event: 'PageView',
        page,
        category: undefined,
        action: undefined,
        label: undefined,
        value: undefined,
        userId
      });
    });
  }
};

export const trackModal = modalName => trackPage('/modal/' + modalName);

export const trackEvent = (
  category,
  action,
  label = undefined,
  value = undefined
) => {
  if (gtmEnabled) {
    determineUserId().then(userId => {
      setData({
        event: 'GAEvent',
        page: undefined,
        category,
        action,
        label,
        value,
        userId
      });
    });
  }
};

export const googleTagManagerMiddleware = store => next => action => {
  if (gtmEnabled && action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = action.payload.pathname;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }

  return next(action);
};
