export function htmlEncodeCharacters(string, chars = '&<>"\'/') {
  const regex = new RegExp('[' + chars + ']', 'g');
  return string.replace(regex, function (match) {
    return '&#x' + match.charCodeAt(0).toString(16) + ';';
  });
}

export function htmlDecode(string) {
  const regex = new RegExp('&#([xX]?[A-Fa-f0-9]{1,2});', 'g');
  return string.replace(regex, function (match, text) {
    const code =
      text[0] === 'x' || text[0] === 'X'
        ? parseInt(text.substring(1), 16)
        : parseInt(text, 10);
    return String.fromCharCode(code);
  });
}

export function forcePageRelayout() {
  const docEl = document.documentElement;
  docEl.style.display = 'none';
  // Force relayout - important to new Android devices
  docEl.clientWidth; // eslint-disable-line no-unused-expressions
  docEl.style.display = '';
}

export function parseQuery(queryString) {
  let query = {};
  if (queryString) {
    let pairs = (
      queryString[0] === '?' ? queryString.substr(1) : queryString
    ).split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      if (pair[0]) {
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
    }
  }
  return query;
}

export function buildQuery(queryObject) {
  let parts = [];
  for (var key in queryObject) {
    if (key !== '' && queryObject.hasOwnProperty(key)) {
      let value = queryObject[key];
      if (value === null || value === undefined) {
        value = '';
      }
      parts.push(
        encodeURIComponent(key) + '=' + encodeURIComponent('' + value)
      );
    }
  }
  parts.sort();
  return parts.join('&');
}

export function originMatches(uri, origin) {
  return (
    uri.startsWith(origin) &&
    (origin.length === uri.length ||
      uri[origin.length] === '/' ||
      uri[origin.length] === '?')
  );
}

const getLocationRegex =
  /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/;
export function getLocation(href) {
  const match = href && href.match(getLocationRegex);
  return (
    match && {
      href: href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7]
    }
  );
}

const siteLocation = getLocation(process.env.REACT_APP_API_URI);
export function isSubdomain(href) {
  const hrefLocation = getLocation(href);
  if (!siteLocation || !hrefLocation) {
    return false;
  }
  const siteParts = siteLocation.hostname.split('.');
  const hrefParts = hrefLocation.hostname.split('.');
  // Note: Assumes a TLD without additional periods ('.com' and not '.co.uk')
  return (
    siteParts[siteParts.length - 1] === hrefParts[hrefParts.length - 1] &&
    siteParts[siteParts.length - 2] === hrefParts[hrefParts.length - 2] &&
    siteLocation.protocol === hrefLocation.protocol
  );
}

export function getFilenameFromUrl(url) {
  if (!url || typeof url !== 'string') {
    return '';
  } else {
    const index = url.lastIndexOf('/');
    if (index === -1) {
      return url;
    } else {
      return url.slice(index + 1);
    }
  }
}

export function safelyCloseWebSocketLink(wsLink) {
  if (wsLink && wsLink.subscriptionClient) {
    const subClient = wsLink.subscriptionClient;
    // Prevent any reconnection loops now that we want this socket closed
    subClient.reconnect = false;
    subClient.clearTryReconnectTimeout();

    // If the socket is in a bad state, any attempts to unsubscribe may throw.
    // Manually unsubscribe everything so unmounting components do not throw.
    Object.keys(subClient.operations).forEach(subId => {
      try {
        subClient.unsubscribe(subId);
      } catch (e) {
        console.warn(e);
      }
    });

    try {
      // Attempt to force-close the socket
      subClient.close(true);
    } catch (e) {
      console.warn(e);
      try {
        // If force-close fails, try a normal close to at least clear the client
        subClient.close(false);
      } catch (ex) {
        console.warn(ex);
      }
    }
  }
}
