/**
 * Zendesk Web Widget with Chat API (Formerly Zopim Chat)
 *
 * @see https://developer.zendesk.com/embeddables/docs/widget/introduction
 */

import { getDefaultedThemeColor } from 'theme';
import { SELECTION_EVENT, trackUserEvent } from 'userActivityMiddleware';

window.zESettings = {
  webWidget: {
    color: {
      theme: getDefaultedThemeColor()
    },
    chat: {
      title: 'Support',
      departments: {
        select: 'Play for Free at WCC.com'
      }
    },
    position: {
      horizontal: 'right',
      vertical: 'bottom'
    },
    offset: {
      horizontal: '-15px',
      vertical: '-15px'
    }
  }
};

let onHideCallbacks = [];
let onShowCallbacks = [];
let onChatStartCallbacks = [];
let onChatEndCallbacks = [];
let toggleButtonLogging = true;

const zendeskLoaded = !!window.zE;
if (!zendeskLoaded) {
  // If Zendesk failed to load, supply a mock that can perform basic actions
  let zeHidden = false;
  let zeOpen = false;
  window.zE = (target, command, params) => {
    if (target === 'webWidget') {
      if (command === 'hide') {
        zeHidden = true;
      } else if (command === 'show') {
        zeHidden = false;
      } else if (command === 'close') {
        zeOpen = false;
        onHideCallbacks.forEach(cb => cb && cb());
      } else if (command === 'open') {
        zeOpen = true;
        onShowCallbacks.forEach(cb => cb && cb());
      }
    } else if (target === 'webWidget:get') {
      if (command === 'display') {
        return zeHidden ? 'hidden' : zeOpen ? 'chat' : 'launcher';
      }
    }
    return undefined;
  };
}

function logSelectionEvent(eventValue, initiator) {
  trackUserEvent({
    activityType: SELECTION_EVENT,
    eventName: 'support-chat',
    eventValue: eventValue,
    initiator: initiator || 'support-chat-button'
  });
}

export function configureZendesk(document, window) {
  window.zE('webWidget', 'hide');

  window.zE('webWidget:on', 'close', () => {
    // Hide all components when the window is hidden.
    if (!toggleButtonLogging) {
      logSelectionEvent('close', 'zendesk-chat-widget-close');
    }
    window.zE('webWidget', 'hide');
    onHideCallbacks.forEach(cb => cb && cb());
    toggleButtonLogging = false;
  });

  window.zE('webWidget:on', 'open', () => {
    onShowCallbacks.forEach(cb => cb && cb());
    toggleButtonLogging = false;
  });

  window.zE('webWidget:on', 'chat:start', () => {
    onChatStartCallbacks.forEach(cb => cb && cb());
  });

  window.zE('webWidget:on', 'chat:end', () => {
    onChatEndCallbacks.forEach(cb => cb && cb());
  });
}

export function toggleChat() {
  const current = window.zE('webWidget:get', 'display');
  if (!current || current === 'hidden' || current === 'launcher') {
    toggleButtonLogging = true;
    logSelectionEvent('open');
    showChat();
  } else {
    toggleButtonLogging = true;
    logSelectionEvent('close');
    hideChat();
  }
}

export function showChat() {
  window.zE('webWidget', 'updateSettings', {
    webWidget: {
      color: {
        theme: '#3971b2'
      }
    }
  });
  window.zE('webWidget', 'show');
  window.zE('webWidget', 'open');
}

export function hideChat() {
  window.zE('webWidget', 'close');
  window.zE('webWidget', 'hide');
}

export function setCallbacks({ onHide, onShow, onChatStart, onChatEnd }) {
  if (onHide && !onHideCallbacks.includes(onHide)) {
    onHideCallbacks.push(onHide);
  }
  if (onShow && !onShowCallbacks.includes(onShow)) {
    onShowCallbacks.push(onShow);
  }
  if (onChatStart && !onChatStartCallbacks.includes(onChatStart)) {
    onChatStartCallbacks.push(onChatStart);
  }
  if (onChatEnd && !onChatEndCallbacks.includes(onChatEnd)) {
    onChatEndCallbacks.push(onChatEnd);
  }
}

export function clearCallbacks({ onHide, onShow, onChatStart, onChatEnd }) {
  if (onHide) {
    onHideCallbacks = onHideCallbacks.filter(c => c !== onHide);
  }
  if (onShow) {
    onShowCallbacks = onShowCallbacks.filter(c => c !== onShow);
  }
  if (onChatStart) {
    onChatStartCallbacks = onChatStartCallbacks.filter(c => c !== onChatStart);
  }
  if (onChatEnd) {
    onChatEndCallbacks = onChatEndCallbacks.filter(c => c !== onChatEnd);
  }
}

export function setUserData(user) {
  if (user) {
    window.zE('webWidget', 'prefill', {
      name: { value: user.displayName, readOnly: false },
      email: { value: user.email, readOnly: false }
    });
  } else if (user === null) {
    clearUserData();
  }
}

export function clearUserData() {
  window.zE('webWidget', 'logout');
}

export function addChatTags(tagsArray) {
  window.zE('webWidget', 'chat:addTags', tagsArray);
}
