import { Howl } from 'howler';

const FADE_LENGTH = 1000;

const howlerIntegration = {
  initialize(soundsData) {
    this.sounds = {};
    this.addSounds(soundsData);
  },

  isSoundAdded(soundName) {
    return !!this.sounds[soundName];
  },

  isSoundLoaded(soundName) {
    return (
      this.sounds[soundName] && this.sounds[soundName].state() === 'loaded'
    );
  },

  addSounds(soundsData, replace = false) {
    const soundNames = Object.getOwnPropertyNames(soundsData);
    soundNames.forEach(soundName => {
      this.addSound(soundName, soundsData[soundName], replace);
    });
  },

  addSound(soundName, soundOptions, replace = false) {
    if (this.sounds[soundName] && !replace) {
      return;
    }
    // Allow strings instead of objects, for when all that is needed is a URL
    if (typeof soundOptions === 'string') {
      soundOptions = { src: [soundOptions] };
    }

    let howl = new Howl(soundOptions);
    if (soundOptions.finalVolume) {
      howl.finalVolume = soundOptions.finalVolume;
    }

    if (this.sounds[soundName]) {
      this.replaceSound(soundName, howl);
    } else {
      this.sounds[soundName] = howl;
    }
  },

  play(soundName, spriteName, noOverlay = false) {
    const sound = this.sounds[soundName];

    if (!sound) {
      return console.warn(`
        The sound '${soundName}' was requested, but soundMiddleware doesn't have anything registered under that name.
      `);
    } else if (spriteName && typeof sound._sprite[spriteName] === 'undefined') {
      const validSprites = Object.keys(sound._sprite).join(', ');

      return console.warn(`
        The sound '${soundName}' was found, but it does not have a sprite specified for '${spriteName}'.
        It only has access to the following sprites: ${validSprites}.
      `);
    }

    if (!noOverlay) {
      sound.lastSpriteName = spriteName;
      sound.play(spriteName);
    } else if (this._isSoundEnded(sound)) {
      sound.lastSpriteName = spriteName;
      sound.play(spriteName);
    }
  },

  fadeIn(soundName) {
    const sound = this.sounds[soundName];

    if (!sound) {
      return console.warn(`
        The sound '${soundName}' was requested, but soundMiddleware doesn't have anything registered under that name.
      `);
    }
    const vol = sound.finalVolume || 1;
    if (!sound.playing() && !sound.fadeState) {
      sound.fade(0, vol, FADE_LENGTH);
      sound.fadeState = 'in';
      sound.once('fade', () => {
        sound.fadeState = undefined;
      });
    } else if (sound.fadeState === 'out') {
      // Clear the pending fade action
      sound.off('fade');
      // Queue a fade-in once the fade-out finishes
      sound.once('fade', () => {
        sound.fade(0, vol, FADE_LENGTH);
        sound.fadeState = 'in';
        sound.once('fade', () => {
          sound.fadeState = undefined;
        });
      });
    } else if (sound.fadeState === 'in') {
      // Reapply the normal fade-in action incase a fade-out was queued
      sound.off('fade');
      sound.once('fade', () => {
        sound.fadeState = undefined;
      });
    }
  },

  fadeOutAndStop(soundName) {
    const sound = this.sounds[soundName];

    if (!sound) {
      return console.warn(`
        The sound '${soundName}' was requested, but soundMiddleware doesn't have anything registered under that name.
      `);
    }

    if (!sound.fadeState) {
      sound.fade(sound.finalVolume || 1, 0, FADE_LENGTH);
      sound.fadeState = 'out';
      sound.once('fade', () => {
        sound.stop();
        sound.fadeState = undefined;
      });
    } else if (sound.fadeState === 'in') {
      // Clear the pending fade action
      sound.off('fade');
      // Queue a fade-out once the fade-in finishes
      sound.once('fade', () => {
        sound.fade(sound.finalVolume || 1, 0, FADE_LENGTH);
        sound.fadeState = 'out';
        sound.once('fade', () => {
          sound.stop();
          sound.fadeState = undefined;
        });
      });
    } else if (sound.fadeState === 'out') {
      // Reapply the normal fade-out action incase a fade-in was queued
      sound.off('fade');
      sound.once('fade', () => {
        sound.stop();
        sound.fadeState = undefined;
      });
    }
  },

  replaceSound(soundName, newSound) {
    const oldSound = this.sounds[soundName];
    let newSoundPlay = false;
    let newSoundFadeIn = false;
    let newSoundSprite;
    if (oldSound) {
      newSoundSprite = oldSound.lastSpriteName;
      newSoundPlay = !this._isSoundEnded(oldSound);
      if (oldSound.playing()) {
        this.fadeOutAndStop(soundName);
        newSoundFadeIn = true;
      } else {
        oldSound.stop();
        oldSound.fadeState = undefined;
        oldSound.off('fade');
      }
      // TODO call unload on replaced sounds (after fading, if applicable)
    }

    this.sounds[soundName] = newSound;
    if (newSoundFadeIn) {
      this.fadeIn(soundName);
    }
    if (newSoundPlay) {
      this.play(soundName, newSoundSprite);
    }
  },

  _isSoundEnded(sound) {
    for (var i = 0; i < sound._sounds.length; i++) {
      if (!sound._sounds[i]._paused || !sound._sounds[i]._ended) {
        return false;
      }
    }
    return true;
  }
};

export default howlerIntegration;
