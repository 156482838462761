import moment from 'moment';

export function padNumber(number, length = 2, fill = '0') {
  let result = '' + number;
  length -= result.length;
  while (length-- > 0) {
    result = fill + result;
  }
  return result;
}

export function formatNumberInput(value) {
  const filtered = value.replace(/[^0-9]/g, '');
  const parsed = parseInt(filtered, 10);
  if (isNaN(parsed)) {
    return '';
  }
  return filtered;
}

export function getInt(val) {
  let number = parseInt(val, 10);
  if (isNaN(number)) {
    return '';
  } else {
    return number;
  }
}

export function formatNumber(value, excludeGrouping) {
  if (typeof value !== 'number') {
    return '';
  }
  return value.toLocaleString('en-US', {
    style: 'decimal',
    useGrouping: !excludeGrouping
  });
}

export function formatMoney(cents, forceDecimal) {
  if (typeof cents !== 'number') {
    return '';
  }
  let decimals = forceDecimal || cents % 100 !== 0 ? 2 : 0;
  return (cents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
    minimumFractionDigits: decimals
  });
}

export function formatPhone(phone) {
  phone = '' + phone;
  let s = phone.replace(/\D/g, '');
  if (s.length === 10 && !phone.startsWith('+')) {
    phone =
      '(' + s.substring(0, 3) + ') ' + s.substring(3, 6) + '-' + s.substring(6);
  }
  return phone;
}

export function formatAddress(line1, line2, city, state, zip, country) {
  let lines = [];
  if (line1) lines.push('' + line1);
  if (line2) lines.push('' + line2);
  let cityStateZip = '';
  if (city) cityStateZip += city;
  if (city && (state || zip)) cityStateZip += ', ';
  if (state) cityStateZip += state;
  if (state && zip) cityStateZip += ' ';
  if (zip) cityStateZip += zip;
  if (cityStateZip) lines.push(cityStateZip);
  if (country) lines.push(country);
  return lines;
}

export function formatGender(gender, abbreviate = true, defaultVal = '') {
  // In iHub, "0" is female, "1" is male
  gender = ('' + gender).toLowerCase();
  if (gender === '1' || gender === 'm' || gender === 'male') {
    return abbreviate ? 'M' : 'Male';
  }
  if (gender === '0' || gender === 'f' || gender === 'female') {
    return abbreviate ? 'F' : 'Female';
  }
  return defaultVal;
}

const dateFormatters = {
  short: new Intl.DateTimeFormat('en', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  }),
  extendedshort: new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }),
  long: new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }),
  full: new Intl.DateTimeFormat('en', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }),
  fullShorter: new Intl.DateTimeFormat('en', {
    // weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }),
  fullShorterNoYear: new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }),
  fullCompact: new Intl.DateTimeFormat('en', {
    hour12: true,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }),
  localtime: new Intl.DateTimeFormat('en', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }),
  fullLocalTime: new Intl.DateTimeFormat('en', {
    hour12: true,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }),
  fullLocalTimeNoYear: new Intl.DateTimeFormat('en', {
    hour12: true,
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  })
};

// short --  05/06/19
// extendedshort --  05/06/2019
// long --  May 6, 2019
// full --  Mon, May 6, 2019, 1:52 PM GMT+4
// fullCompact --  05/06/2019, 1:52 PM
// localtime --  1:52 PM GMT+4
// fullLocalTime -- 05/06/2019, 1:52 PM GMT+4

export function formatDate(date, format = 'long') {
  if (moment.isMoment(date)) {
    date = date.toDate();
  }
  if (!date || !(date instanceof Date) || isNaN(date.valueOf())) {
    return '';
  }
  let formatter = dateFormatters[format] || dateFormatters['long'];
  return formatter.format(date);
}
