export const CLEAR_SESSION_ERROR = 'app/CLEAR_SESSION_ERROR';
export const PLAY_SOUND = 'app/PLAY_SOUND';
export const LOOP_SOUND = 'app/LOOP_SOUND';
export const STOP_LOOP_SOUND = 'app/STOP_LOOP_SOUND';
export const SUPPRESS_SECONDARY_CONTENT = 'app/SUPPRESS_SECONDARY_CONTENT';
export const TOGGLE_CASINO_SOUNDS = 'app/TOGGLE_CASINO_SOUNDS';

export function playSound(sound) {
  return {
    type: PLAY_SOUND,
    meta: { sound }
  };
}

export function loopSound(sound) {
  return {
    type: LOOP_SOUND,
    meta: {
      sound: `${sound}.loop`
    }
  };
}

export function stopLoopSound(sound) {
  return {
    type: STOP_LOOP_SOUND,
    meta: { sound }
  };
}

export function clearSessionError() {
  return {
    type: CLEAR_SESSION_ERROR
  };
}

export function setSuppressSecondaryContent(suppress) {
  return {
    type: SUPPRESS_SECONDARY_CONTENT,
    value: suppress
  };
}

export function toggleCasinoSounds() {
  return {
    type: TOGGLE_CASINO_SOUNDS
  };
}
