import moment from 'moment';
import 'moment-timezone';
import { replaceAllStrings } from 'utils/strings';

const MOMENT_TZ = moment.tz.guess();

/*
Create a Date object with support for multiple types of input:
 - Server datetime string: "2022-01-01 16:00:00" (assumed UTC)
 - Server LocalDateTime string: "2022-01-01T16:00:00" (assumed UTC)
 - ISO 8601 datetime string: "2022-01-01T16:00:00Z"
 - iHub datetime string: "2022/01/01 16:00:00" (assumed UTC)
 - Unix millisecond timestamp: 1652208637966
 - Moment object
 - Date object
*/
export function makeDate(value) {
  if (value && typeof value === 'string') {
    value = replaceAllStrings(value.trim(), '/', '-');
    if (value[10] === ' ') {
      value = value.substring(0, 10) + 'T' + value.substring(11);
    }
    if (!value.endsWith('Z')) {
      value += 'Z';
    }
    return new Date(value);
  } else if (typeof value === 'number' && value > 0) {
    return new Date(value);
  } else if (moment.isMoment(value)) {
    return value.toDate();
  }
  return value;
}

/*
Create a Moment object with support for multiple types of input:
 - Server datetime string: "2022-01-01 16:00:00"
 - Server LocalDateTime string: "2022-01-01T16:00:00" (assumed UTC)
 - ISO 8601 datetime string: "2022-01-01T16:00:00Z"
 - iHub datetime string: "2022/01/01 16:00:00"
 - Unix millisecond timestamp: 1652208637966
 - Moment object
 - Date object
*/
export function makeMoment(value) {
  if (value && typeof value === 'string') {
    value = replaceAllStrings(value.trim(), '/', '-');
    return moment.utc(value).local();
  } else if (typeof value === 'number' && value > 0) {
    return moment(value);
  } else if (value instanceof Date) {
    return moment(value);
  }
  return value;
}

/*
Create a moment with its timezone name set to enable the 'z' format token to print the timezone name.
NOTE: This is not required to print dates in the local timezone. Moments and
Dates print in the local timezone by default. It is only required for printing
the name of the timezone itself.
*/
export function makeMomentTz(value) {
  value = makeMoment(value);
  if (moment.isMoment(value)) {
    value = value.tz(MOMENT_TZ);
  }
  return value;
}

/*
Convert a date to the server datetime string format: "YYYY-MM-DD HH:mm:ss.sss"
Supports Date objects, Moment objects, and values that a Date can be constructed from.
Returns an empty string if the date object is invalid.
*/
export function makeServerDateString(date) {
  if (!date) {
    return '';
  } else if (!(date instanceof Date) && !moment.isMoment(date)) {
    date = makeDate(date);
  }

  if (isNaN(date.valueOf())) {
    return '';
  }

  // Desired format is "YYYY-MM-DD HH:mm:ss.sss"
  return date.toISOString().replace('T', ' ').replace('Z', '');
}

// Convert date to ISO9075 MySQL DateTime format string. Pass the date to
// use or something convertable to a Date.
export const getIso9075DateString = makeServerDateString;

// Convert an ISO9075 MySQL DateTime string to a JS Date object.
export const fromIso9075DateString = makeDate;

export function truncateSeconds(value) {
  if (!value) {
    return value;
  } else if (moment.isMoment(value)) {
    return moment(value).seconds(0).milliseconds(0);
  } else if (value instanceof Date) {
    return new Date(value).setSeconds(0, 0);
  } else {
    throw new Error('Expected date object, received ' + typeof value);
  }
}

export function isValidDate(value) {
  if (!value) {
    return false;
  } else if (moment.isMoment(value)) {
    return value.isValid();
  } else if (value instanceof Date) {
    return !isNaN(value);
  } else {
    return false;
  }
}

export function beginningOfDay(value) {
  if (!value) {
    return value;
  } else if (moment.isMoment(value)) {
    return moment(value).hour(0).minute(0).seconds(0).milliseconds(0);
  } else if (value instanceof Date) {
    return new Date(value).setHours(0, 0, 0, 0);
  } else {
    throw new Error('Expected date object, received ' + typeof value);
  }
}
