import moment from 'moment';
import { create } from 'timesync';
import { fromIso9075DateString } from 'utils/dates';
import { startTimeSync, stopTimeSync, updateTimeSync } from './timeSyncReducer';

const SERVER_URL = `${process.env.REACT_APP_API_URI}/time`;

class TimeSync {
  constructor() {
    this._timeDifference = 0;
    this._ts = create({
      server: SERVER_URL,
      interval: 3600000 + Math.random() * 1800000
    });
  }

  startSynchronization(store) {
    store.dispatch(startTimeSync());
    this._ts.on('change', offset => {
      this._timeDifference = offset;
      store.dispatch(updateTimeSync(offset));
    });
  }

  stopSynchronization(store) {
    store.dispatch(stopTimeSync());
    this._ts.destroy();
  }

  get timeDifference() {
    return this._timeDifference;
  }

  serverNow() {
    return this._ts.now();
  }

  toLocalTime(dateValue) {
    return adjustTime(dateValue, -this._timeDifference);
  }

  toServerTime(dateValue) {
    return adjustTime(dateValue, this._timeDifference);
  }
}

function adjustTime(dateValue, deltaMs) {
  if (!dateValue) {
    return dateValue;
  } else if (typeof dateValue === 'number') {
    return dateValue + deltaMs;
  } else if (typeof dateValue === 'string') {
    const dateMs = fromIso9075DateString(dateValue).valueOf();
    if (isNaN(dateMs)) {
      throw new Error('Could not convert string to date: "' + dateValue + '"');
    }
    return new Date(dateMs + deltaMs);
  } else if (moment.isMoment(dateValue)) {
    if (!dateValue.isValid()) {
      throw new Error('Invalid date');
    }
    return moment(dateValue).add(deltaMs, 'milliseconds');
  } else if (dateValue instanceof Date) {
    const dateMs = dateValue.valueOf();
    if (isNaN(dateMs)) {
      throw new Error('Invalid date');
    }
    return new Date(dateMs + deltaMs);
  } else {
    throw new Error(
      'Expected date object or convertable value, received "' +
        dateValue +
        '" (' +
        typeof dateValue +
        ')'
    );
  }
}

const TimeSyncSingleton = new TimeSync();

export default TimeSyncSingleton;
