import gql from 'graphql-tag';
import fragments from './fragments';

export const USER_QUERY = gql`
  query MyUser {
    user {
      ...UserFields
    }
  }
  ${fragments.user}
`;
