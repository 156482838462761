import MobileDetect from 'mobile-detect';
import { parseQuery } from 'utils/web';

/*
The React website can run in one of several primary modes:
- mobile-app: embedded in native mobile app webview
- led-wall: embedded in native app webview

There are also secondary groupings:
- embedded: any mode that is embedded in a webview and needs to restrict links or popups, includes mobile-app and led-wall.
*/

export const mobileDetect = new MobileDetect(window.navigator.userAgent);
const mobileUserAgent = !!mobileDetect.mobile();
const mobileOs = mobileDetect.os();
const queryData = parseQuery(window.location.search);

const mobileApp = queryData.mkey === process.env.REACT_APP_MOBILE_SECRET;

const ledWall = queryData.hasOwnProperty('ledWallMode');

export function initializeMode() {
  if (isMobileApp()) {
    document.documentElement.classList.add('mobile-app');
    document.body.classList.add('mobile-app');
  } else if (isLedWall()) {
    document.documentElement.classList.add('led-wall');
    document.body.classList.add('led-wall');
  }
  if (isEmbedded()) {
    document.documentElement.classList.add('embedded');
    document.body.classList.add('embedded');
  }
}

export function isMobileApp() {
  return mobileApp;
}

export function isLedWall() {
  return ledWall;
}

export function isMobileDevice_DEPRECATED() {
  return !isLedWall() && (mobileUserAgent || isMobileApp());
}

export function isEmbedded() {
  return isMobileApp() || isLedWall();
}

export function isAppleDevice() {
  return mobileOs === 'iOS';
}
