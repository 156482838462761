import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectTheme, makeSelectThemeColor } from './themeReducer';

const mapStateToProps = createStructuredSelector({
  theme: makeSelectTheme(),
  themeColor: makeSelectThemeColor()
});

export default connect(mapStateToProps);
