import React from 'react';
import LoadingMask from './LoadingMask';
import './LoadingIndicator.css';

function LoadingIndicator({ expand, overlay, size, ...rest }) {
  const sizeClass = size ? ` size-${size}` : '';
  const indicator = (
    <div className={'loading-indicator' + sizeClass} {...rest}>
      <div className="loading-animation" />
      <LoadingMask />
    </div>
  );

  if (expand || overlay) {
    const wrapClass = 'loading-indicator-' + (overlay ? 'overlay' : 'expand');
    return <div className={wrapClass + sizeClass}>{indicator}</div>;
  } else {
    return indicator;
  }
}

export default LoadingIndicator;
