export const SET_NEW_REWARDS_COUNT = 'RewardsButton/SET_NEW_REWARDS_COUNT';
export const INC_NEW_REWARDS_COUNT = 'RewardsButton/INC_NEW_REWARDS_COUNT';

export function setNewRewardsCount(count) {
  return {
    type: SET_NEW_REWARDS_COUNT,
    payload: { count }
  };
}

export function incNewRewardsCount() {
  return {
    type: INC_NEW_REWARDS_COUNT
  };
}
