import { createSelector } from 'reselect';

export const START_TIME_SYNC = 'Timesync/START_TIME_SYNC';
export const STOP_TIME_SYNC = 'Timesync/STOP_TIME_SYNC';
export const UPDATE_TIME_SYNC = 'Timesync/UPDATE_TIME_SYNC';

export function startTimeSync() {
  return {
    type: START_TIME_SYNC
  };
}

export function stopTimeSync() {
  return {
    type: STOP_TIME_SYNC
  };
}

export function updateTimeSync(timeDifference) {
  return {
    type: UPDATE_TIME_SYNC,
    payload: { timeDifference }
  };
}

const selectTimeSyncState = state => state.get('timeSync');

export const selectTimeDifference = createSelector(
  selectTimeSyncState,
  timeSync => timeSync.timeDifference
);

const initialState = { timeDifference: 0, status: '' };

export default function timeSyncReducer(state = initialState, action) {
  switch (action.type) {
    case START_TIME_SYNC: {
      return Object.assign({}, state, { status: 'started' });
    }
    case STOP_TIME_SYNC: {
      return Object.assign({}, state, { status: 'stopped' });
    }
    case UPDATE_TIME_SYNC: {
      return Object.assign({}, state, {
        timeDifference: action.payload.timeDifference,
        status: 'running'
      });
    }
    default:
      return state;
  }
}
