import SOUND_CONSTANTS from 'utils/soundConstants';
import buttonPress from 'sounds/button_press.mp3';

export default function createSoundRegistry() {
  // Global sounds to load immediately when page is initialized.
  // Feature or device-specific sounds should instead be added in the
  //   proper components so they are only downloaded when/if needed.

  return {
    // Our soundsData is an object. The keys are the names of our sounds.
    // If no additional configuration is necessary, we can just pass a string as the path to our file.
    // If additional configuration is needed, an object can be passed with howler.js options.
    [SOUND_CONSTANTS.CLICK_BUTTON_SOUND]: buttonPress
  };
}
