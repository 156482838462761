import { fromJS } from 'immutable';
import { TOGGLE_FEED_VISIBILITY } from './actions';

const initialState = fromJS({
  showFeed: false
});

export default function socialFeedReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FEED_VISIBILITY:
      const show =
        action.payload.showFeed === true || action.payload.showFeed === false
          ? action.payload.showFeed
          : !state.get('showFeed');
      return state.set('showFeed', show);

    default:
      return state;
  }
}
