export const SET_WINNER = 'Tournament/SET_WINNER';

export function setWinner({
  tournamentId,
  tournamentFinalized,
  userId,
  name,
  rank
}) {
  return {
    type: SET_WINNER,
    payload: { tournamentId, tournamentFinalized, userId, name, rank }
  };
}
