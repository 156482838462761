/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import { getCasinoSounds, setCasinoSounds } from 'utils/localStorage';
import { SUPPRESS_SECONDARY_CONTENT, TOGGLE_CASINO_SOUNDS } from './actions';

const soundOn = getCasinoSounds();

// The initial state of the App
const initialState = fromJS({
  suppressSecondaryContent: false,
  casinoSoundsOn: soundOn
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPRESS_SECONDARY_CONTENT:
      return state.set('suppressSecondaryContent', !!action.value);

    case TOGGLE_CASINO_SOUNDS:
      const soundsOn = !state.get('casinoSoundsOn');
      setCasinoSounds(soundsOn);

      return state.set('casinoSoundsOn', soundsOn);

    default:
      return state;
  }
}

export default appReducer;
