import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { DedupLink } from 'apollo-link-dedup';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import Observable from 'zen-observable';
import { clearAuthState, getToken, isAuthenticated } from 'utils/auth';
import { getCredentialsSetting } from 'utils/cookies';
import { getLocale } from 'utils/localStorage';
import { ERROR_INVALID_SESSION } from 'utils/messages';
import { isMobileApp } from 'utils/mode';
import { safelyCloseWebSocketLink } from 'utils/web';
import introspectionQueryResultData from './fragmentTypes.json';

export default function configureApollo(history) {
  const wsUri = process.env.REACT_APP_GRAPHQLWS_API_URI + 'cv';

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_API_URI,
    credentials: getCredentialsSetting()
  });

  // Create a WebSocket link.
  // wsLink is shared by swappableWsLink and recreateWebSocketConnection
  let wsLink;
  const swappableWsLink = new ApolloLink(operation => {
    return wsLink
      ? wsLink.request(operation) || Observable.of()
      : Observable.of();
  });

  const recreateWebSocketConnection = function () {
    safelyCloseWebSocketLink(wsLink);

    const userToken = getToken();
    const locale = getLocale();
    wsLink = userToken
      ? new WebSocketLink({
          uri: wsUri + '/' + userToken,
          options: {
            reconnect: true,
            connectionParams: locale ? { locale } : undefined
          }
        })
      : null;
  };
  recreateWebSocketConnection();

  const customHeadersLink = setContext((_, { headers }) => {
    let newHeaders = { ...headers };

    newHeaders['X-WCC-WindcreekMobileApp'] = isMobileApp();
    newHeaders['X-WCC-Is-Casinoverse'] = !isMobileApp();

    const locale = getLocale();
    if (locale) {
      newHeaders['X-WCC-Locale'] = locale;
    }

    // get the authentication token from local storage if it exists
    const token = getToken();
    if (token) {
      newHeaders['authorization'] = `Bearer ${token}`;
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: newHeaders
    };
  });

  const networkErrorListeners = [];
  const addNetworkErrorListener = listener => {
    if (listener && !networkErrorListeners.includes(listener)) {
      networkErrorListeners.push(listener);
      return true;
    }
    return false;
  };
  const removeNetworkErrorListener = listener => {
    const index = networkErrorListeners.indexOf(listener);
    if (index >= 0) {
      networkErrorListeners.splice(index, 1);
      return true;
    }
    return false;
  };

  const graphQLErrorListeners = [];
  const addGraphQLErrorListener = listener => {
    if (listener && !graphQLErrorListeners.includes(listener)) {
      graphQLErrorListeners.push(listener);
      return true;
    }
    return false;
  };
  const removeGraphQLErrorListener = listener => {
    const index = graphQLErrorListeners.indexOf(listener);
    if (index >= 0) {
      graphQLErrorListeners.splice(index, 1);
      return true;
    }
    return false;
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (networkError.statusCode === 401 && isAuthenticated()) {
        // 401 UNAUTHORIZED indicates session token is expired
        const redirectOptions = {
          error: {
            statusCode: networkError.statusCode,
            message: ERROR_INVALID_SESSION
          }
        };
        clearAuthState('/login', redirectOptions);
      } else if (networkError.statusCode === 412) {
        // 412 PRECONDITION FAILED indicates maintenance mode is on
        // Handled in MaintananceMode component
      } else if (networkError.statusCode === 423) {
        // 423 LOCKED indicates user needs to accept new terms of service
        history.push('/terms-of-service-updated');
      }
      networkErrorListeners.forEach(listener => listener(networkError));
    }
    if (graphQLErrors?.length) {
      graphQLErrorListeners.forEach(listener => listener(graphQLErrors));
    }
  });

  const dedupLink = new DedupLink();

  const combinedHttpLink = errorLink.concat(
    customHeadersLink.concat(dedupLink.concat(httpLink))
  );

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    swappableWsLink,
    combinedHttpLink
  );

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'User':
          return `User:${object.userId}`;
        case 'Posts':
          return `Posts`;
        case 'MaintenanceMode':
          return 'MaintenanceMode';
        case 'GameTitle':
          return `GameTitle:${object.name}`;
        case 'IpGeoLocationData':
          return 'IpGeoLocationData';
        case 'QuestLeaderboard':
          return `QuestLeaderboard:${object.questId}`;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    }
  });

  const client = new ApolloClient({
    link,
    cache: cache,
    connectToDevTools: process.env.REACT_APP_CONNECT_TO_DEV_TOOLS === 'true' // Disable this for Prod! See https://github.com/apollographql/apollo-client-devtools
  });

  client.recreateWebSocketConnection = recreateWebSocketConnection;
  client.addNetworkErrorListener = addNetworkErrorListener;
  client.removeNetworkErrorListener = removeNetworkErrorListener;
  client.addGraphQLErrorListener = addGraphQLErrorListener;
  client.removeGraphQLErrorListener = removeGraphQLErrorListener;

  return client;
}
