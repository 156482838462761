import React, { Component } from 'react';
import { Notifier } from '@airbrake/browser';
import PropTypes from 'prop-types';
import './ErrorBoundary.css';

let airbrakeNotifier = null;

const ERROR_REPEAT_LIMIT = 5;
let errorCounts = {};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorId: null
    };

    if (
      !airbrakeNotifier &&
      process.env.REACT_APP_ENVIRONMENT &&
      process.env.REACT_APP_AIRBRAKE_ID &&
      process.env.REACT_APP_AIRBRAKE_SECRET
    ) {
      airbrakeNotifier = new Notifier({
        projectId: process.env.REACT_APP_AIRBRAKE_ID,
        projectKey: process.env.REACT_APP_AIRBRAKE_SECRET,
        environment: process.env.REACT_APP_ENVIRONMENT,
        keysBlocklist: [
          // Filter out any UUIDs that might be reset password tokens or other sensitive tokens
          /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/gi
        ]
      });

      airbrakeNotifier.addFilter(function (notice) {
        notice.errors = notice.errors.filter(filterIgnoredErrors);
        if (!notice.errors.length) {
          return null;
        } else {
          notice.errors.forEach(error => {
            errorCounts[error.message] = (errorCounts[error.message] || 0) + 1;
          });
        }
        notice.context.version = process.env.REACT_APP_JENKINS_BUILD_VERSION;
        notice.context.tag = process.env.REACT_APP_JENKINS_BUILD_TAG;
        notice.context.url = redactUrl(notice.context.url);
        return notice;
      });
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    if (airbrakeNotifier) {
      airbrakeNotifier
        .notify({
          error: error,
          params: { info: info }
        })
        .then(notice => {
          this.setState({ errorId: notice && notice.id });
        });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        !this.props.invisible && (
          <div className="alert alert-danger error-boundary">
            An error occurred while processing your request. Please try again
            later.
            {this.state.errorId && (
              <div className="error-id">Error ID: {this.state.errorId}</div>
            )}
          </div>
        )
      );
    }
    return this.props.children;
  }
}

function redactUrl(url) {
  // Redact any UUID's before sending to airbrake, as they can be equivalent to passwords
  return url.replace(
    /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/gi,
    'UUID_REDACTED'
  );
}

const THROTTLED_ERROR_MESSAGES = [
  {
    regex:
      /^(Network error: )?Network request failed with status (401|412|423)/,
    ratio: 0.0
  },
  {
    regex:
      /^(Network error: )?Response not successful: Received status code (401|412|423)/,
    ratio: 0.0
  },
  {
    regex: /^(Network error: )?Failed to fetch\.?$/,
    ratio: 0.0
  },
  {
    regex:
      /^(Network error: )?NetworkError when attempting to fetch resource\.?$/,
    ratio: 0.0
  },
  {
    regex: /^Access is denied\.?$/,
    ratio: 0.0
  },
  {
    regex: /^Group is rate limited\.?$/,
    ratio: 0.0
  },
  {
    regex: /^Unspecified error\.?$/,
    ratio: 0.0
  },
  {
    regex: /^(Network error: )?The Internet connection appears to be offline/,
    ratio: 0.0
  },
  {
    regex: /^The user aborted a request\.?$/,
    ratio: 0.0
  },
  {
    regex:
      /^(Network error: )?The operation couldn’t be completed\. Software caused connection abort\.?$/,
    ratio: 0.0
  },
  {
    regex: /^(Network error: )?The network connection was lost\.?$/,
    ratio: 0.0
  },
  {
    regex:
      /^(GraphQL error: )?Exception while fetching data \(\/userActivityPageNavigation\) : GraphQL Data Fetching Error$/,
    ratio: 0.0
  },
  {
    regex: /^(Network error: )?Timeout( \(.\))?\.?$/,
    ratio: 0.01
  },
  {
    regex: /^unhandled rejection with no reason given$/,
    ratio: 0.0
  },
  {
    regex: /^(ChunkLoadError: )?Loading (CSS )?chunk [0-9]+ failed\./,
    ratio: 0.0
  },
  {
    regex: /^(EncodingError: *)?Failed to execute 'decodeAudioData'/,
    ratio: 0.01
  }
];

function filterIgnoredErrors(error) {
  if (!error || !error.message) {
    return false;
  }
  const rule = THROTTLED_ERROR_MESSAGES.find(r => r.regex.test(error.message));
  const count = errorCounts[error.message] || 0;
  return count < ERROR_REPEAT_LIMIT && (!rule || Math.random() < rule.ratio);
}

export const clearAirbrakeState = () => {
  errorCounts = {};
};

ErrorBoundary.propTypes = {
  invisible: PropTypes.bool
};

export default ErrorBoundary;
