import { clearGtmUserId } from 'googleTagManagerMiddleware';
import { clearAirbrakeState } from 'containers/ErrorBoundary';
import {
  clearPreferences,
  getLocalData,
  removeLocalData,
  setLocalData
} from 'utils/localStorage';
import { ERROR_EXTERNAL_LOGOUT } from 'utils/messages';
import { clearUserData } from 'utils/zendesk';

const USER_AUTH_TOKEN = 'wch_ux_auth_token';
let lastAuthenticationState = false;
let globalApolloClient = null;
let globalHistory = null;

export const getToken = () => getLocalData(USER_AUTH_TOKEN, null, false);

export const setToken = token => {
  lastAuthenticationState = !!token;
  setLocalData(USER_AUTH_TOKEN, token, false);
};

export const removeToken = () => {
  lastAuthenticationState = false;
  removeLocalData(USER_AUTH_TOKEN);
};

export const isAuthenticated = () => !!getToken();

export const clearSessionStorage = () => {
  removeToken();
  clearUserData();
  clearAirbrakeState();
  clearGtmUserId();
  clearPreferences();
};

function handleStorageEvent(ev) {
  if (ev.key === USER_AUTH_TOKEN || ev.key === null) {
    let authenticated = isAuthenticated();
    if (lastAuthenticationState && !authenticated) {
      clearAuthState('/login', { error: { message: ERROR_EXTERNAL_LOGOUT } });
    }
    lastAuthenticationState = authenticated;
  }
}

export function registerLogoutHandlers(apolloClient, history) {
  lastAuthenticationState = isAuthenticated();
  globalApolloClient = apolloClient;
  globalHistory = history;
  window.addEventListener('storage', handleStorageEvent);
}

export function clearAuthState(redirectPath, redirectOptions) {
  lastAuthenticationState = false;
  clearSessionStorage();
  if (globalApolloClient) {
    globalApolloClient.resetStore();
    globalApolloClient.recreateWebSocketConnection();
  }
  if (globalHistory && redirectPath) {
    globalHistory.push(redirectPath, redirectOptions);
  }
}
