import { fromJS } from 'immutable';
import { SET_WSCORE_WHEEL_ACTIVE } from './actions';

const initialState = fromJS({ active: false });

export default function wscoreWheelReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WSCORE_WHEEL_ACTIVE:
      return state.set('active', action.payload.active);

    default:
      return state;
  }
}
