import React from 'react';
import PropTypes from 'prop-types';

function A11ySvg({ component, alt, title, ...otherProps }) {
  const Component = component || 'svg';
  if (title === true) {
    // title defaults to alt if passed as true
    title = alt;
  }
  return (
    <React.Fragment>
      <Component
        role="presentation"
        aria-hidden="true"
        focusable="false"
        title={title ? title : undefined}
        {...otherProps}
      />
      {!!alt && <span className="sr-only">{alt}</span>}
    </React.Fragment>
  );
}

A11ySvg.propTypes = {
  alt: PropTypes.string.isRequired,
  component: PropTypes.any,
  children: PropTypes.any
};

export default A11ySvg;
