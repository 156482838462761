import { fromJS } from 'immutable';
import { SET_MENU_OPEN } from './actions';

const initialState = fromJS({
  open: false,
  openLevel: 0
});

export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENU_OPEN:
      const { open, openLevel } = action.payload;
      return !open && openLevel < state.get('openLevel')
        ? state
        : state.set('open', open).set('openLevel', openLevel);

    default:
      return state;
  }
}
