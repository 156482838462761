import React from 'react';
import Loadable from 'react-loadable';
import LoadingIndicator from 'components/LoadingIndicator';

const RETRY_DELAY = 1000;

export function retryingLoader(importFn, attempts = 3) {
  return function retry(remaining = attempts) {
    return new Promise((resolve, reject) => {
      importFn()
        .then(resolve)
        .catch(error => {
          setTimeout(() => {
            if (--remaining > 0) {
              retry(remaining).then(resolve, reject);
            } else {
              reject(error);
            }
          }, RETRY_DELAY);
        });
    });
  };
}

export function DefaultLoadingComponent({ error, pastDelay, retry }) {
  if (error) {
    return (
      <div className="alert alert-danger">
        Failed to load site. Please try again.
      </div>
    );
  } else if (pastDelay) {
    return <LoadingIndicator expand />;
  } else {
    return null;
  }
}

export function NullLoadingComponent() {
  return null;
}

export function createLoader(
  importFn,
  attempts = 3,
  delay = 1000,
  loadingComponent = DefaultLoadingComponent
) {
  return Loadable({
    loader: retryingLoader(importFn, attempts),
    loading: loadingComponent,
    delay: delay
  });
}
