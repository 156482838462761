import { fromJS } from 'immutable';
import { SET_WINNER } from './actions';

const initialState = fromJS({
  winners: JSON.stringify({})
});

export default function tournamentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WINNER:
      const { tournamentId, tournamentFinalized, userId, name, rank } =
        action.payload;
      const winners = JSON.parse(state.get('winners'));

      return state.set(
        'winners',
        JSON.stringify({
          ...winners,
          [tournamentId]: {
            tournamentFinalized,
            userId,
            name,
            rank
          }
        })
      );

    default:
      return state;
  }
}
