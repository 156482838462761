import { fromJS } from 'immutable';
import { TOGGLE_LEADERBOARD_OPEN, UPDATE_LEADERBOARD_OPEN } from './actions';

const initialState = fromJS({ leaderboardOpen: false });

export default function gamePageReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LEADERBOARD_OPEN:
      return state.set('leaderboardOpen', action.payload.leaderboardOpen);
    case TOGGLE_LEADERBOARD_OPEN:
      return state.update('leaderboardOpen', open => !open);

    default:
      return state;
  }
}
