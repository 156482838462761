/**
 *
 * App
 *
 * This component decides which sub-app to load.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { createLoader } from 'utils/createLoader';
import { isLedWall, isMobileApp } from 'utils/mode';

const LedWallLoader = createLoader(() =>
  import(/* webpackChunkName: "led-wall" */ './LedWall')
);
const MobileAppLoader = createLoader(() =>
  import(/* webpackChunkName: "mobile-app" */ './MobileApp')
);
const DesktopWebLoader = createLoader(() =>
  import(/* webpackChunkName: "desktop-web" */ './DesktopWeb')
);

function App({ location }) {
  const AppComp = isLedWall()
    ? LedWallLoader
    : isMobileApp()
    ? MobileAppLoader
    : DesktopWebLoader;

  return (
    <>
      <Helmet
        titleTemplate="%s - Wind Creek Online Casino"
        defaultTitle="Wind Creek Online Casino"
      />

      <AppComp location={location} />
    </>
  );
}

export default withRouter(App);
