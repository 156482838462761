export const TRANSITION_PAGE_OUT_SOUND = 'transitionPageOut';
export const TRANSITION_PAGE_IN_SOUND = 'transitionPageIn';
export const HOVER_BUTTON_SOUND = 'hoverButton';
export const CLICK_BUTTON_SOUND = 'clickIcon';

const soundConstants = {
  TRANSITION_PAGE_OUT_SOUND,
  TRANSITION_PAGE_IN_SOUND,
  HOVER_BUTTON_SOUND,
  CLICK_BUTTON_SOUND
};

export default soundConstants;
