export const UPDATE_LEADERBOARD_OPEN = 'gamePage/UPDATE_LEADERBOARD_OPEN';
export const TOGGLE_LEADERBOARD_OPEN = 'gamePage/TOGGLE_LEADERBOARD_OPEN';

export function updateLeaderboardOpen(open) {
  return {
    type: UPDATE_LEADERBOARD_OPEN,
    payload: { leaderboardOpen: open }
  };
}

export function toggleLeaderboardOpen() {
  return {
    type: TOGGLE_LEADERBOARD_OPEN
  };
}
