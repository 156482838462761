export const SET_MENU_OPEN = 'Header/SET_MENU_OPEN';

export const MENU_LEVEL_USER = 0;
export const MENU_LEVEL_FORCED = 1;

export function setMenuOpen(open, openLevel = MENU_LEVEL_USER) {
  return {
    type: SET_MENU_OPEN,
    payload: { open, openLevel }
  };
}
