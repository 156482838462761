/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form/immutable'; // Import from redux-form/immutable is required to use immutable.
import { combineReducers } from 'redux-immutable';
import { CLEAR_SESSION_ERROR } from 'containers/App/actions';
import tournamentReducer from 'containers/DesktopNotificationTray/TournamentFinalized/reducer';
import drawingEventsReducer from 'containers/Drawings/utils/eventReducer';
import socialFeedReducer from 'containers/Feed/reducer';
import gamePageReducer from 'containers/GamePage/reducer';
import wscoreWheelReducer from 'containers/WScoreWheelPopup/reducer';
import headerReducer from 'components/Header/reducer';
import introTourReducer from 'components/IntroTour/reducer';
import onboardTourReducer from 'components/OnboardTour/reducer';
import rewardsButtonReducer from 'components/RewardsButton/reducer';
import themeReducer from 'theme/themeReducer';
import timeSyncReducer from 'utils/TimeSync/timeSyncReducer';
import globalReducer from './containers/App/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by using react-router-redux@5
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
  authRedirect: null
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      let existingRedirect = state.get('authRedirect');
      existingRedirect = existingRedirect && existingRedirect.toJS();

      const location = action.payload;
      let authRedirect;
      if (location.state && location.state.redirectFrom) {
        authRedirect = location.state && location.state.redirectFrom;
      } else if (location.pathname === '/' || location.pathname === '/login') {
        authRedirect = existingRedirect;
      } else {
        authRedirect = null;
      }

      return state.merge({ location, authRedirect });

    case CLEAR_SESSION_ERROR:
      return state.setIn(['location', 'state'], undefined);

    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    route: routeReducer,
    global: globalReducer,
    form: formReducer,
    drawingEvents: drawingEventsReducer,
    introTour: introTourReducer,
    onboardTour: onboardTourReducer,
    feed: socialFeedReducer,
    header: headerReducer,
    tournament: tournamentReducer,
    rewardsButton: rewardsButtonReducer,
    gamePage: gamePageReducer,
    timeSync: timeSyncReducer,
    theme: themeReducer,
    wscoreWheel: wscoreWheelReducer,
    ...injectedReducers
  });
}
