import React from 'react';
import A11ySvg from 'components/A11ySvg';

function LoadingMask() {
  return (
    <A11ySvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className="loading-mask"
      alt="Loading"
    >
      <path
        className="fill"
        d="M-4.13-4V36h40V-4Zm5,20.18A15.09,15.09,0,0,1,22.63,2.33c-3.22,2.6-6.29,6.61-8.83,12.8a26.25,26.25,0,0,1,.61-8,14.35,14.35,0,0,0-1.68,1.11c-.1.15-1.49,4.35-.49,11.59-.4,1.41-.77,3.23-1.07,4.86a39.33,39.33,0,0,1-3.3-18.5A9.64,9.64,0,0,0,6.05,7.68C5.72,8.9,5.46,18.6,10.58,29c.82-.49,1.85-1,1.93-1.27a32.13,32.13,0,0,1,.29-4.27,68.75,68.75,0,0,0,2.75,7.76A15.22,15.22,0,0,1,.87,16.18Zm13.18,2.05c.19-.52,3.31-9.92,10-15.12a15.62,15.62,0,0,1,4.6,4.49h0C23.87,11.5,19,18.07,17,28.77A41.51,41.51,0,0,1,14,18.23ZM18,31.11V31c.19-.85.37-1.75.56-2.6C20.87,17.89,25.24,12,29.18,8.61A14.89,14.89,0,0,1,31,14,15.2,15.2,0,0,1,18,31.11Z"
      />
    </A11ySvg>
  );
}

export default LoadingMask;
