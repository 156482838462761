import { createSelector } from 'reselect';

export const THEME_LOAD_COMPLETE = 'Theme/THEME_LOAD_COMPLETE';

export function themeLoadComplete(theme) {
  return {
    type: THEME_LOAD_COMPLETE,
    payload: { theme }
  };
}

const selectThemeState = state => state.get('theme');

export const makeSelectTheme = () =>
  createSelector(selectThemeState, themeState => themeState.theme);

export const makeSelectThemeColor = () =>
  createSelector(
    selectThemeState,
    themeState => (themeState.theme && themeState.theme.color) || '#97131F'
  );

const initialState = {
  theme: null
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case THEME_LOAD_COMPLETE: {
      return Object.assign({}, state, {
        theme: action.payload.theme
      });
    }
    default:
      return state;
  }
}
