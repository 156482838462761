import gql from 'graphql-tag';
import moment from 'moment';
import { buildQuery } from 'utils/web';

let apolloClient = null;
let lastPage = null;

export const setUserActivityApolloClient = client => {
  apolloClient = client;
};

export const SELECTION_EVENT = 'selectionEvent';
export const PAGE_NAVIGATION = 'pageNavigation';
export const WEB_NAVIGATION = 'webNavigation';

const SUBMIT_USER_ACTIVITY_EVENT = gql`
  mutation MyUserActivityEvent($input: UserActivityEventInput!) {
    userActivityEvent(input: $input)
  }
`;

export const trackUserEvent = ({
  activityType,
  page = null,
  referrerPage = null,
  initiator = null,
  eventName = null,
  eventValue = null,
  eventParams = null
}) => {
  if (!referrerPage) {
    referrerPage = lastPage;
  }
  if (eventParams && typeof eventParams === 'object') {
    eventParams = buildQuery(eventParams) || null;
  }
  apolloClient.mutate({
    mutation: SUBMIT_USER_ACTIVITY_EVENT,
    variables: {
      input: {
        activityType,
        page,
        referrerPage,
        initiator,
        eventName,
        eventValue,
        eventParams,
        localTime: moment().toISOString(true),
        userAgent: navigator.userAgent
      }
    }
  });
};

export const userActivityMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = action.payload.pathname;
    if (nextPage !== lastPage) {
      trackUserEvent({
        activityType: PAGE_NAVIGATION,
        page: nextPage,
        referrerPage: lastPage,
        initiator: action.payload.state ? action.payload.state.initiator : null
      });
      lastPage = nextPage;
    }
  }
  return next(action);
};
