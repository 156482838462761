import React from 'react';
import PropTypes from 'prop-types';

function A11yFontIcon({
  icon = 'warning',
  size = '',
  large = false,
  fixed = false,
  spin = false,
  pulse = false,
  className = '',
  alt = '',
  title,
  ...otherProps
}) {
  let setClassName = 'fa fa-' + icon;
  if (size) {
    setClassName += ' fa-' + size;
  }
  if (large) {
    setClassName += ' fa-lg';
  }
  if (fixed) {
    setClassName += ' fa-fw';
  }
  if (spin) {
    setClassName += ' fa-spin';
  }
  if (pulse) {
    setClassName += ' fa-pulse';
  }
  if (className) {
    setClassName += ' ' + className;
  }
  if (title === true) {
    // title defaults to alt if passed as true
    title = alt;
  }
  return (
    <React.Fragment>
      <span
        role="presentation"
        aria-hidden="true"
        focusable="false"
        className={setClassName}
        title={title ? title : undefined}
        {...otherProps}
      />
      {!!alt && <span className="sr-only">{alt}</span>}
    </React.Fragment>
  );
}

A11yFontIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),
  large: PropTypes.bool,
  fixed: PropTypes.bool,
  spin: PropTypes.bool,
  pulse: PropTypes.bool
};

export default A11yFontIcon;
