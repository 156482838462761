import { linkify } from 'react-linkify';

export const LINKIFY_PROPERTIES = {
  rel: 'noopener noreferrer nofollow',
  target: '_blank'
};

// Limit TLDs to common ones that aren't likely to cause typos to become links
const defaultTlds =
  'com|edu|gov|net|org|biz|info|co|us|uk|ca|aw|cw|ly|io|me|tv|cc|app'.split(
    '|'
  );

export default function configureLinkify() {
  linkify
    .tlds(defaultTlds, false)
    .set({
      fuzzyLink: true,
      fuzzyIP: false,
      fuzzyEmail: true
    })
    .add('ftp:', null) // disable ftp
    .add('//', null); // disable protocol-relative links
}
